import React, { useEffect, useState } from 'react'
import {Modal, Button} from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios';

const timeZoneTable = `US	US/Alaska		Link	−09:00	−08:00	AKST	AKDT	backward	Link to America/Anchorage
US	US/Aleutian		Link	−10:00	−09:00	HST	HDT	backward	Link to America/Adak
US	US/Arizona		Link	−07:00	−07:00	MST	backward	Link to America/Phoenix
US	US/Central		Link	−06:00	−05:00	CST	CDT	backward	Link to America/Chicago
US	US/East-Indiana		Link	−05:00	−04:00	EST	EDT	backward	Link to America/Indiana/Indianapolis
US	US/Eastern		Link	−05:00	−04:00	EST	EDT	backward	Link to America/New_York
US	US/Hawaii		Link	−10:00	−10:00	HST	backward	Link to Pacific/Honolulu
US	US/Indiana-Starke		Link	−06:00	−05:00	CST	CDT	backward	Link to America/Indiana/Knox
US	US/Michigan		Link	−05:00	−04:00	EST	EDT	backward	Link to America/Detroit
US	US/Mountain		Link	−07:00	−06:00	MST	MDT	backward	Link to America/Denver
US	US/Pacific		Link	−08:00	−07:00	PST	PDT	backward	Link to America/Los_Angeles`

// let availableVersions = ["lab", "v1.0"]
// let availableVersions = []

const timeZones = timeZoneTable.split('\n').map(x => x.split('\t')).map(x => x[1])

const timeZoneOptions = [<option key="blank" style={{ color: 'gray' }}>Time Zone</option>, ...timeZones.map(x => <option id={x} key={x}>{x}</option>)]

//const versionOptions = [<option key="blank" style={{ color: 'gray' }}>Select an Alairo version for this installation</option>, ...availableVersions.map(x => <option id={x} key={x}>{x}</option>)]

interface alarmTeamMapping {
    alarmName: string,
    teamName: string
}

interface ConfigForm {
    version: string
    installation: string,
    numHospitals: number,
    hospitalName: string,
    timeZone: string,
    alarmTeamMappings: alarmTeamMapping[],
    unitWideAlerts: string[],
    hospitals: string[],
    hostIp: string,
    sipIp: string,
    sipPort: string,
    sipUsername: string,
    sipPassword: string,
    allTouchIp: string,
    allTouchPort: number,
    featureAO: boolean,
    featureIS: boolean,
    featureAFP: boolean,
    featureARC: boolean,
    featureLive: boolean,
    featureUptime: boolean,
    integrationAllTouch: boolean,
    integrationSync: boolean,
    integrationSip: boolean,
    tracingEnabled: boolean,
    dialbackEnabled: boolean
}

const configDetailsSchema = Yup.object().shape({
    installation: Yup.string().trim()
    .matches(/^[aA-zZ]+$/ , 'Installation name is not in correct format')
    .required("Installation name is required"),
    hospitalName: Yup.string().required("Hospital name is required"),
    allTouchIp: Yup.string().required("IP address is required"),
    allTouchPort: Yup.string().required("Port is required"),
    sipIp: Yup.string().required("IP address is required"),
    sipPort: Yup.string().required("Port is required"),
    hostIp: Yup.string().required("IP address is required"),
})

const initialValues: ConfigForm = {
    version: 'lab',
    installation: '',
    hospitalName: '',
    numHospitals: 1,
    timeZone: '',
    alarmTeamMappings: [
        {
            alarmName: "CODE_BLUE",
            teamName: "Code Blue"
        }
    ],
    unitWideAlerts: [
        "BED_EXIT"
    ],
    hospitals: [''],
    hostIp: '',
    allTouchIp: '172.17.0.34',
    allTouchPort: 27001,
    sipIp: '172.17.0.6',
    sipPort: '5060',
    sipUsername: '',
    sipPassword: '',
    featureAO: true,
    featureIS: false,
    featureAFP: false,
    featureARC: false,
    featureLive: false,
    featureUptime: false,
    integrationAllTouch: false,
    integrationSync: false,
    integrationSip: false,
    tracingEnabled: true,
    dialbackEnabled: true
}

interface ConfigVersion {
  displayName: string,
  value: string
}

export default function Landing() {
    const updateData = (_: any) => { }

    const [allTouchConfigVisible, setAllTouchConfigVisible] = useState(false)
    const [sipConfigVisible, setSipConfigVisible] = useState(false)

    const [availableVersions, setAvailableVersion] = useState<ConfigVersion[]>([])
    const [installationPackage, setInstallationPackage] = useState("")
    const [imagePackage, setImagePackage] = useState("")
    const [submitError, setSubmitError] = useState(null)
    const [loading, setLoading] = useState(false)

    const [submitValues, setSubmitValues] = useState<ConfigForm | null>(null)
    const handleClose = () => {
      setSubmitValues(null)
    }

    const submitForm = () => {
      const values = submitValues
      setSubmitValues(null)
      setInstallationPackage("")
      axios.post('/api/config', values)
      .then((response) => {
          if (response.status === 200) {
              console.log('config response', response, 'package', response.data.package)
              setInstallationPackage("static/" + response.data.package)
          }
      })
      .catch((e) => {
        console.log('error', e)
        setSubmitError(e.response.message)
      })
      setImagePackage("")
      axios.post('/api/presigned_url', { version: values?.version})
      .then(response => {
          if (response.status === 200) {
              console.log('presigned_url response', response)
              setImagePackage(response.data)
          }
      })
    }

    const formik = useFormik<ConfigForm>({
        initialValues,
        validationSchema: configDetailsSchema,
        onSubmit: setSubmitValues,
    })

    useEffect(() => {
        async function fetchData() {
            const { data } = await axios.get("/api/releases")
            setAvailableVersion(data.map((value) => {
              return {
                displayName: value.name.replace("update_", "Update: ").replace("-", " -> "),
                value: value.name
              }
            }))
        }

        fetchData()
        // setAvailableVersion(["lab", "demo", "0.1.0", "0.2.0", "0.3.0", "0.3.1", "0.3.2", "0.3.3", "0.3.4", "0.3.5", "0.3.6", "0.3.7"])
    }, [])

    return (<>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
            <div className="card mb-5 mb-xl-10">
                <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_alairo_setup"
                    aria-expanded="true"
                    aria-controls="kt_alairo_setup"
                >
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Alairo Setup</h3>
                    </div>
                </div>

                <div id="kt_alairo_setup" className="collapse show">
                    <div className="card-body border-top p-9">

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Alairo Version</label>

                            <div className="col-lg-8 fv-row">
                                <div style={{ 'display': 'flex' }}>
                                    <i style={{ margin: 'auto 0 auto 0', width: '30px', paddingLeft: '8px' }}
                                        className="fas fa-hashtag icon-3x"></i>&nbsp;
                                    <select
                                        className="form-select form-select-solid form-select-lg fw-bold"
                                        id="version"
                                        placeholder="Version"
                                        {...formik.getFieldProps('version')}
                                    >
                                        {/*{versionOptions}*/}
                                        {
                                            availableVersions.map(x => <option id={x.value} key={x.value}>{x.displayName}</option>)
                                        }
                                    </select>
                                    {formik.touched.version && formik.errors.version && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.version}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Installation</label>

                            <div className="col-lg-8 fv-row">
                                <div style={{ display: 'flex' }}>
                                    <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path
                                                d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                                fill="#000000" opacity="0.3" />
                                            <path
                                                d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                                                fill="#000000" />
                                            <path
                                                d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                                fill="#000000" />
                                        </g>
                                    </svg></span>
                                    <input
                                        type="text"
                                        id="installation"
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="Enter the name of the installation"
                                        {...formik.getFieldProps('installation')}
                                    />
                                    {formik.touched.installation && formik.errors.installation && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.installation}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                <span className="required">Time Zone</span>
                            </label>
                            <div className="col-lg-8 fv-row">
                                <div style={{ display: 'flex' }}>
                                    <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="9" />
                                            <path
                                                d="M11.7357634,20.9961946 C6.88740052,20.8563914 3,16.8821712 3,12 C3,11.9168367 3.00112797,11.8339369 3.00336944,11.751315 C3.66233009,11.8143341 4.85636818,11.9573854 4.91262842,12.4204038 C4.9904938,13.0609191 4.91262842,13.8615942 5.45804656,14.101772 C6.00346469,14.3419498 6.15931561,13.1409372 6.6267482,13.4612567 C7.09418079,13.7815761 8.34086797,14.0899175 8.34086797,14.6562185 C8.34086797,15.222396 8.10715168,16.1034596 8.34086797,16.2636193 C8.57458427,16.423779 9.5089688,17.54465 9.50920913,17.7048097 C9.50956962,17.8649694 9.83857487,18.6793513 9.74040201,18.9906563 C9.65905192,19.2487394 9.24857641,20.0501554 8.85059781,20.4145589 C9.75315358,20.7620621 10.7235846,20.9657742 11.7357634,20.9960544 L11.7357634,20.9961946 Z M8.28272988,3.80112099 C9.4158415,3.28656421 10.6744554,3 12,3 C15.5114513,3 18.5532143,5.01097452 20.0364482,7.94408274 C20.069657,8.72412177 20.0638332,9.39135321 20.2361262,9.6327358 C21.1131932,10.8600506 18.0995147,11.7043158 18.5573343,13.5605384 C18.7589671,14.3794892 16.5527814,14.1196773 16.0139722,14.886394 C15.4748026,15.6527403 14.1574598,15.137809 13.8520064,14.9904917 C13.546553,14.8431744 12.3766497,15.3341497 12.4789081,14.4995164 C12.5805657,13.664636 13.2922889,13.6156126 14.0555619,13.2719546 C14.8184743,12.928667 15.9189236,11.7871741 15.3781918,11.6380045 C12.8323064,10.9362407 11.963771,8.47852395 11.963771,8.47852395 C11.8110443,8.44901109 11.8493762,6.74109366 11.1883616,6.69207022 C10.5267462,6.64279981 10.170464,6.88841096 9.20435656,6.69207022 C8.23764828,6.49572949 8.44144409,5.85743687 8.2887174,4.48255778 C8.25453994,4.17415686 8.25619136,3.95717082 8.28272988,3.80112099 Z M20.9991771,11.8770357 C20.9997251,11.9179585 21,11.9589471 21,12 C21,16.9406923 17.0188468,20.9515364 12.0895088,20.9995641 C16.970233,20.9503326 20.9337111,16.888438 20.9991771,11.8770357 Z"
                                                fill="#000000" opacity="0.3" />
                                        </g>
                                    </svg></span>
                                    &nbsp;
                                    <select
                                        className="form-select form-select-solid form-select-lg fw-bold"
                                        id="timeZone"
                                        placeholder="Time Zone"
                                        {...formik.getFieldProps('timeZone')}
                                    >
                                        {timeZoneOptions}
                                    </select>
                                    {formik.touched.timeZone && formik.errors.timeZone && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.timeZone}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                <span className="required">Number of Hospitals</span>
                            </label>

                            <div className="col-lg-8 fv-row">
                                <div style={{ 'display': 'flex' }}>
                                    <i style={{ margin: 'auto 0 auto 0', width: '30px', paddingLeft: '8px' }}
                                        className="fas fa-hashtag icon-3x"></i>&nbsp;
                                    <input
                                        type="number"
                                        id="numHospitals"
                                        className="form-control form-control-lg form-control-solid"
                                        {...formik.getFieldProps('numHospitals')}
                                    />
                                    {formik.touched.numHospitals && formik.errors.numHospitals && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.numHospitals}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                            </label>

                            <div className="col-lg-8 fv-row">
                                <div style={{ display: 'flex' }}>
                                    <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path
                                                d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                                fill="#000000" />
                                            <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                                            <path
                                                d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                                                fill="#000000" opacity="0.3" />
                                        </g>
                                    </svg></span>
                                    &nbsp;
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        id="hospitalName"
                                        placeholder="Enter the hospital name"
                                        {...formik.getFieldProps('hospitalName')}
                                    />
                                    {formik.touched.hospitalName && formik.errors.hospitalName && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.hospitalName}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Host IP</label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <rect fill="#000000" x="3" y="13" width="18" height="7" rx="2" />
                                            <path d="M17.4029496,9.54910207 L15.8599014,10.8215022 C14.9149052,9.67549895 13.5137472,9 12,9 C10.4912085,9 9.09418404,9.67104182 8.14910121,10.8106159 L6.60963188,9.53388797 C7.93073905,7.94090645 9.88958759,7 12,7 C14.1173586,7 16.0819686,7.94713944 17.4029496,9.54910207 Z M20.4681628,6.9788888 L18.929169,8.25618985 C17.2286725,6.20729644 14.7140097,5 12,5 C9.28974232,5 6.77820732,6.20393339 5.07766256,8.24796852 L3.54017812,6.96885102 C5.61676443,4.47281829 8.68922234,3 12,3 C15.3153667,3 18.3916375,4.47692603 20.4681628,6.9788888 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                        </g>
                                    </svg></span>

                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='IP / Host'
                                        {...formik.getFieldProps('hostIp')}
                                    />
                                    {formik.touched.hostIp && formik.errors.hostIp && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.hostIp}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6 px-3'>
                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='integrations[]'
                                    defaultChecked={true}
                                    onChange={() => formik.setFieldValue('tracingEnabled', !formik.values.tracingEnabled)}
                                />
                                <span className='d-flex flex-column align-items-start'>
                                    <span className='fw-bold fs-5 mb-0'>Enable local monitoring</span>
                                    <span className='text-muted fs-6'>
                                        Grafana driven monitoring and tracing.
                                    </span>
                                </span>
                            </label>
                        </div>
                        <div className='row mb-6 px-3'>
                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='integrations[]'
                                    defaultChecked={true}
                                    onChange={() => formik.setFieldValue('dialbackEnabled', !formik.values.dialbackEnabled)}
                                />
                                <span className='d-flex flex-column align-items-start'>
                                    <span className='fw-bold fs-5 mb-0'>Enable dialback lookup</span>
                                    <span className='text-muted fs-6'>
                                        Provides SipMonitor for installation onto the RGS
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="card mb-5 mb-xl-10">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_features"
                  aria-expanded="true"
                  aria-controls="kt_features"
                >
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Features</h3>
                    </div>
                </div>

                <div id="kt_features" className="collapse show">

                    <div className="card-body border-top px-9 py-9">
                        <label className="form-check form-check-custom form-check-solid align-items-start">
                            <input
                              className="form-check-input me-3"
                              type="checkbox"
                              name="featureAO"
                              checked={true}
                              disabled={true}
                              // defaultChecked={data.successfulPayments}
                              // onChange={() =>
                              //   updateData({
                              //         // successfulPayments: !data.successfulPayments,
                              //     },
                              //   )
                              // }
                            />

                            <span className="d-flex flex-column align-items-start">
                            <span className="fw-bolder fs-5 mb-0">Alairo Orchestrator</span>
                            <span className="text-muted fs-6">
                                SYSTEM INTELLIGENCE and CONTROL
                            </span>
                        </span>
                        </label>
                    </div>
                </div>

                <div id="kt_features" className="collapse show">
                    <div className="card-body border-top px-9 py-9">
                        <label className="form-check form-check-custom form-check-solid align-items-start">
                            <input
                              className="form-check-input me-3"
                              type="checkbox"
                              name="features"
                              // defaultChecked={data.successfulPayments}
                              onChange={() =>
                                formik.setFieldValue('featureIS', !formik.values.featureIS)
                              }
                            />

                            <span className="d-flex flex-column align-items-start">
                            <span className="fw-bolder fs-5 mb-0">Alairo InSight</span>
                            <span className="text-muted fs-6">
                                PATIENT CARE ANALYTICS
                            </span>
                        </span>
                        </label>
                    </div>
                </div>

                <div id="kt_features" className="collapse show">

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                              className='form-check-input me-3'
                              type='checkbox'
                              name='features'
                              // defaultChecked={data.successfulPayments}
                              onChange={() =>
                                formik.setFieldValue('featureAFP', !formik.values.featureAFP)
                              }
                            />

                            <span className='d-flex flex-column align-items-start'>
                            <span className='fw-bolder fs-5 mb-0'>Alairo AFP</span>
                            <span className='text-muted fs-6'>
                                ACTIVE FALL PREDICTION
                            </span>
                        </span>
                        </label>
                    </div>
                </div>

                <div id='kt_features' className='collapse show'>

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                              className='form-check-input me-3'
                              type='checkbox'
                              name='features'
                              // defaultChecked={data.successfulPayments}
                              onChange={() =>
                                formik.setFieldValue('featureARC', !formik.values.featureARC)
                              }
                            />

                            <span className='d-flex flex-column align-items-start'>
                            <span className='fw-bolder fs-5 mb-0'>Alairo ARC</span>
                            <span className='text-muted fs-6'>
                                ALARM RECEIVER and COMMUNICATOR
                            </span>
                        </span>
                        </label>
                    </div>
                </div>

                <div id='kt_features' className='collapse show'>

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                              className='form-check-input me-3'
                              type='checkbox'
                              name='features'
                              // defaultChecked={data.successfulPayments}
                              onChange={() =>
                                formik.setFieldValue('featureLive', !formik.values.featureLive)
                              }
                            />

                            <span className='d-flex flex-column align-items-start'>
                            <span className='fw-bolder fs-5 mb-0'>Alairo Live</span>
                            <span className='text-muted fs-6'>
                                SYNCHRONIZED COMMUNICATIONS
                            </span>
                        </span>
                        </label>
                    </div>
                </div>

                <div id='kt_features' className='collapse show'>

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                              className='form-check-input me-3'
                              type='checkbox'
                              name='features'
                              // defaultChecked={data.successfulPayments}
                              onChange={() =>
                                formik.setFieldValue('featureUptime', !formik.values.featureUptime)
                              }
                            />

                            <span className='d-flex flex-column align-items-start'>
                            <span className='fw-bolder fs-5 mb-0'>Alairo Uptime</span>
                            <span className='text-muted fs-6'>
                                SERVER MONITORING
                            </span>
                        </span>
                        </label>
                    </div>
                </div>
            </div> */}

            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_integrations'
                    aria-expanded='true'
                    aria-controls='kt_integrations'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Integrations</h3>
                    </div>
                </div>

                <div id='kt_integrations' className='collapse show'>

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                                className='form-check-input me-3'
                                type='checkbox'
                                name='integrations[]'
                                // defaultChecked={data.successfulPayments}
                                onChange={() => {
                                    const alltouchIntegrationState = !formik.values.integrationAllTouch
                                    formik.setFieldValue('integrationAllTouch', alltouchIntegrationState)
                                    setAllTouchConfigVisible(alltouchIntegrationState)
                                }
                                }
                            />

                            <span className='d-flex flex-column align-items-start'>
                                <span className='fw-bolder fs-5 mb-0'>Rauland Responder AllTouch</span>
                                <span className='text-muted fs-6'>
                                    Trigger events in Responder 5.
                                </span>
                            </span>
                        </label>
                    </div>
                </div>

                <div id='kt_integrations' className='collapse show'>

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                                className='form-check-input me-3'
                                type='checkbox'
                                name='integrations[]'
                                // defaultChecked={data.successfulPayments}
                                onChange={() =>
                                    formik.setFieldValue('integrationSync', !formik.values.integrationSync)
                                }
                            />

                            <span className='d-flex flex-column align-items-start'>
                                <span className='fw-bolder fs-5 mb-0'>Rauland Sync</span>
                                <span className='text-muted fs-6'>
                                    Care team assignment data integration.
                                </span>
                            </span>
                        </label>
                    </div>
                </div>

                <div id='kt_integrations' className='collapse show'>

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                                disabled={true}
                                className='form-check-input me-3'
                                type='checkbox'
                                name='integrations[]'
                                // defaultChecked={data.successfulPayments}
                                onChange={() =>
                                    updateData({
                                        // successfulPayments: !data.successfulPayments,
                                    }
                                    )
                                }
                            />

                            <span className='d-flex flex-column align-items-start' style={{ opacity: '0.5' }}>
                                <span className='fw-bolder fs-5 mb-0'>Epic Care Team Assignments <span style={{ fontStyle: 'oblique' }}>(coming soon)</span></span>
                                <span className='text-muted fs-6'>
                                    Care team assignment data integration.
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
                <div id='kt_integrations' className='collapse show'>

                    <div className='card-body border-top px-9 py-9'>
                        <label className='form-check form-check-custom form-check-solid align-items-start'>
                            <input
                                className='form-check-input me-3'
                                type='checkbox'
                                name='integrations[]'
                                // defaultChecked={data.successfulPayments}
                                onChange={() => {
                                    const sipIntegrationState = !formik.values.integrationSip
                                    formik.setFieldValue('integrationSip', sipIntegrationState)
                                    setSipConfigVisible(sipIntegrationState)
                                }
                                }
                            />

                            <span className='d-flex flex-column align-items-start'>
                                <span className='fw-bolder fs-5 mb-0'>Rauland Sip</span>
                                <span className='text-muted fs-6'>
                                    Receive and respond to Responder 5 events.
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <hr></hr>

            <div style={{ margin: '16px', fontSize: '0.8em', background: 'rgba(0,0,0,0.1)', padding: '20px', borderRadius: '7.5px' }}>
                <h2 style={{ padding: '20px' }}>Advanced Configuration</h2>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_routing_config'
                        aria-expanded='true'
                        aria-controls='kt_routing_config'
                    >
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>Routing Configuration</h3>
                        </div>
                    </div>

                    <div id='kt_routing_config' className='collapse show'>
                        <div className='card-body border-top p-9'>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Alarm / Team Mapping</label>

                                <div className='col-lg-8 fv-row'>
                                    <></>
                                    <table>
                                        {formik.values.alarmTeamMappings.map(
                                            (alarmTeamMapping, idx) =>
                                                <tr>
                                                    <td>
                                                        <span className="svg-icon svg-icon-primary svg-icon-2x">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z" fill="#000000" />
                                                                    <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z" fill="#000000" opacity="0.3" />
                                                                    <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control form-control-lg form-control-solid'
                                                            placeholder='Alarm Name'
                                                            {...formik.getFieldProps(`alarmTeamMappings[${idx}].alarmName`)}
                                                        />
                                                        {/* {formik.touched.installation && formik.errors.installation && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.installation}</div>
                                        </div>
                                    )} */}
                                                    </td>
                                                    <td style={{ width: '40px' }}>&nbsp;</td>
                                                    <td>
                                                        <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                                <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                                <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                            </g>
                                                        </svg>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control form-control-lg form-control-solid'
                                                            placeholder='Team Name'
                                                            {...formik.getFieldProps(`alarmTeamMappings[${idx}].teamName`)}
                                                        />
                                                        {/* {formik.touched.installation && formik.errors.installation && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.installation}</div>
                                        </div>
                                    )} */}
                                                    </td>
                                                </tr>
                                        )}
                                    </table>
                                    <div className="form-group">
                                        <label className="col-lg-2 col-form-label text-right"></label>
                                        <div className="col-lg-4">
                                            <a href="javascript:;" data-repeater-create="" className="btn btn-sm font-weight-bolder btn-light-primary" onClick={() => formik.setFieldValue('alarmTeamMappings', formik.values.alarmTeamMappings.concat({ alarmName: '', teamName: '' }))}>
                                                <i className="la la-plus"></i>Add
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Unit-wide
                                    Alerts</label>

                                <div className="col-lg-8 fv-row">
                                    <table>
                                        {formik.values.unitWideAlerts.map(
                                            (unitWideAlert, idx) =>
                                                <tr>
                                                    <td>
                                                        <div className="col-lg-8 fv-row">
                                                            <div style={{ display: 'flex' }}>
                                                                <span
                                                                    style={{ margin: 'auto' }}
                                                                    className="svg-icon svg-icon-primary svg-icon-2x">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path
                                                                                d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z"
                                                                                fill="#000000" />
                                                                            <path
                                                                                d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z"
                                                                                fill="#000000" opacity="0.3" />
                                                                            <path
                                                                                d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                                                                                fill="#000000" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </div></div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder="Alarm Name"
                                                            {...formik.getFieldProps(`unitWideAlerts[${idx}]`)}
                                                        />
                                                    </td>
                                                </tr>,
                                        )}
                                    </table>
                                    <div className="form-group">
                                        <label className="col-lg-2 col-form-label text-right"></label>
                                        <div className="col-lg-4">
                                            <a href="javascript:;" data-repeater-create=""
                                                className="btn btn-sm font-weight-bolder btn-light-primary"
                                                onClick={() => formik.setFieldValue('unitWideAlerts', formik.values.unitWideAlerts.concat(''))}>
                                                <i className="la la-plus"></i>Add Unit-wide Alert
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {allTouchConfigVisible && (
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_alltouch_config'
                            aria-expanded='true'
                            aria-controls='kt_alltouch_config'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Rauland AllTouch Configuration</h3>
                            </div>
                        </div>

                        <div id='kt_alltouch_config' className='collapse show'>
                            <div className='card-body border-top p-9'>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>IP / Host</label>

                                    <div className='col-lg-8 fv-row'>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <rect fill="#000000" x="3" y="13" width="18" height="7" rx="2" />
                                                    <path d="M17.4029496,9.54910207 L15.8599014,10.8215022 C14.9149052,9.67549895 13.5137472,9 12,9 C10.4912085,9 9.09418404,9.67104182 8.14910121,10.8106159 L6.60963188,9.53388797 C7.93073905,7.94090645 9.88958759,7 12,7 C14.1173586,7 16.0819686,7.94713944 17.4029496,9.54910207 Z M20.4681628,6.9788888 L18.929169,8.25618985 C17.2286725,6.20729644 14.7140097,5 12,5 C9.28974232,5 6.77820732,6.20393339 5.07766256,8.24796852 L3.54017812,6.96885102 C5.61676443,4.47281829 8.68922234,3 12,3 C15.3153667,3 18.3916375,4.47692603 20.4681628,6.9788888 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                </g>
                                            </svg></span>

                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='IP / Host'
                                                {...formik.getFieldProps('allTouchIp')}
                                            />
                                            {formik.touched.allTouchIp && formik.errors.allTouchIp && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.allTouchIp}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Port</label>

                                    <div className='col-lg-8 fv-row'>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M5,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,19 C20,19.5522847 19.5522847,20 19,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,5 C4,4.44771525 4.44771525,4 5,4 Z M9,7 C8.44771525,7 8,7.44771525 8,8 L8,11 C8,11.5522847 8.44771525,12 9,12 C9.55228475,12 10,11.5522847 10,11 L10,8 C10,7.44771525 9.55228475,7 9,7 Z M12,15 C11.1715729,15 10.5,15.6715729 10.5,16.5 L10.5,18 L13.5,18 L13.5,16.5 C13.5,15.6715729 12.8284271,15 12,15 Z M15,7 C14.4477153,7 14,7.44771525 14,8 L14,11 C14,11.5522847 14.4477153,12 15,12 C15.5522847,12 16,11.5522847 16,11 L16,8 C16,7.44771525 15.5522847,7 15,7 Z" fill="#000000" />
                                                </g>
                                            </svg></span>


                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Port'
                                                {...formik.getFieldProps('allTouchPort')}
                                            />
                                            {formik.touched.allTouchPort && formik.errors.allTouchPort && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.allTouchPort}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                {sipConfigVisible && (
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_sip_config'
                            aria-expanded='true'
                            aria-controls='kt_sip_config'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Rauland SIP Configuration</h3>
                            </div>
                        </div>

                        <div id='kt_sip_config' className='collapse show'>
                            <div className='card-body border-top p-9'>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>IP / Host</label>

                                    <div className='col-lg-8 fv-row'>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <rect fill="#000000" x="3" y="13" width="18" height="7" rx="2" />
                                                    <path d="M17.4029496,9.54910207 L15.8599014,10.8215022 C14.9149052,9.67549895 13.5137472,9 12,9 C10.4912085,9 9.09418404,9.67104182 8.14910121,10.8106159 L6.60963188,9.53388797 C7.93073905,7.94090645 9.88958759,7 12,7 C14.1173586,7 16.0819686,7.94713944 17.4029496,9.54910207 Z M20.4681628,6.9788888 L18.929169,8.25618985 C17.2286725,6.20729644 14.7140097,5 12,5 C9.28974232,5 6.77820732,6.20393339 5.07766256,8.24796852 L3.54017812,6.96885102 C5.61676443,4.47281829 8.68922234,3 12,3 C15.3153667,3 18.3916375,4.47692603 20.4681628,6.9788888 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                </g>
                                            </svg></span>


                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='IP / Host'
                                                {...formik.getFieldProps('sipIp')}
                                            />
                                            {formik.touched.sipIp && formik.errors.sipIp && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.sipIp}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Port</label>

                                    <div className='col-lg-8 fv-row'>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M5,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,19 C20,19.5522847 19.5522847,20 19,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,5 C4,4.44771525 4.44771525,4 5,4 Z M9,7 C8.44771525,7 8,7.44771525 8,8 L8,11 C8,11.5522847 8.44771525,12 9,12 C9.55228475,12 10,11.5522847 10,11 L10,8 C10,7.44771525 9.55228475,7 9,7 Z M12,15 C11.1715729,15 10.5,15.6715729 10.5,16.5 L10.5,18 L13.5,18 L13.5,16.5 C13.5,15.6715729 12.8284271,15 12,15 Z M15,7 C14.4477153,7 14,7.44771525 14,8 L14,11 C14,11.5522847 14.4477153,12 15,12 C15.5522847,12 16,11.5522847 16,11 L16,8 C16,7.44771525 15.5522847,7 15,7 Z" fill="#000000" />
                                                </g>
                                            </svg></span>

                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Port'
                                                {...formik.getFieldProps('sipPort')}
                                            />
                                            {formik.touched.sipPort && formik.errors.sipPort && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.sipPort}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Username</label>

                                    <div className='col-lg-8 fv-row'>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ margin: 'auto' }} className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                </g>
                                            </svg></span>

                                            <input
                                                type='text'
                                                autoComplete='off'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Username'
                                                {...formik.getFieldProps('sipUsername')}
                                            />
                                            {formik.touched.sipUsername && formik.errors.sipUsername && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.sipUsername}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Password</label>

                                    <div className='col-lg-8 fv-row'>
                                        <div style={{ display: 'flex' }}>
                                            <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <polygon fill="#000000" opacity="0.3" transform="translate(8.885842, 16.114158) rotate(-315.000000) translate(-8.885842, -16.114158) " points="6.89784488 10.6187476 6.76452164 19.4882481 8.88584198 21.6095684 11.0071623 19.4882481 9.59294876 18.0740345 10.9659914 16.7009919 9.55177787 15.2867783 11.0071623 13.8313939 10.8837471 10.6187476" />
                                                    <path d="M15.9852814,14.9852814 C12.6715729,14.9852814 9.98528137,12.2989899 9.98528137,8.98528137 C9.98528137,5.67157288 12.6715729,2.98528137 15.9852814,2.98528137 C19.2989899,2.98528137 21.9852814,5.67157288 21.9852814,8.98528137 C21.9852814,12.2989899 19.2989899,14.9852814 15.9852814,14.9852814 Z M16.1776695,9.07106781 C17.0060967,9.07106781 17.6776695,8.39949494 17.6776695,7.57106781 C17.6776695,6.74264069 17.0060967,6.07106781 16.1776695,6.07106781 C15.3492424,6.07106781 14.6776695,6.74264069 14.6776695,7.57106781 C14.6776695,8.39949494 15.3492424,9.07106781 16.1776695,9.07106781 Z" fill="#000000" transform="translate(15.985281, 8.985281) rotate(-315.000000) translate(-15.985281, -8.985281) " />
                                                </g>
                                            </svg></span>

                                            <input
                                                type='password'
                                                autoComplete='off'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Password'
                                                {...formik.getFieldProps('sipPassword')}
                                            />
                                            {formik.touched.sipPassword && formik.errors.sipPassword && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.sipPassword}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Create Installation Package'}
                {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </button>
        </form>

        <Modal show={submitValues !!== null} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Form Values</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-sm table-striped">
              <thead>
                <th scope="col">Field</th>
                <th scope="col">Value</th>
                <th scope="col">Default Value</th>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Version</th>
                  <td>{submitValues?.version}</td>
                  <td>{initialValues.version}</td>
                </tr>
                <tr>
                  <th scope="row">Installation</th>
                  <td>{submitValues?.installation}</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">Time Zone</th>
                  <td>{submitValues?.timeZone}</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">Number of Hospitals</th>
                  <td>{submitValues?.numHospitals}</td>
                  <td>{initialValues.numHospitals}</td>
                </tr>
                <tr>
                  <th scope="row">Host IP</th>
                  <td>{submitValues?.hostIp}</td>
                  <td>0.0.0.0</td>
                </tr>
                <tr>
                  <th scope="row">Enable local monitoring</th>
                  <td className={submitValues?.tracingEnabled !== initialValues.tracingEnabled ? 'text-danger' : ''}>{submitValues?.tracingEnabled === true ? 'Enabled' : 'Disabled'}</td>
                  <td>{initialValues.tracingEnabled === true ? 'Enabled' : 'Disabled'}</td>
                </tr>
                <tr>
                  <th scope="row">Enable dialback lookup</th>
                  <td className={submitValues?.dialbackEnabled !== initialValues.dialbackEnabled ? 'text-danger' : ''}>{submitValues?.dialbackEnabled === true ? 'Enabled' : 'Disabled'}</td>
                  <td>{initialValues.dialbackEnabled === true ? 'Enabled' : 'Disabled'}</td>
                </tr>
                <tr className="table-primary">
                  <th scope="row">Integration: Rauland Responder AllTouch</th>
                  <td className={submitValues?.integrationAllTouch !== initialValues.integrationAllTouch ? 'text-danger' : ''}>{submitValues?.integrationAllTouch === true ? 'Enabled' : 'Disabled'}</td>
                  <td>{initialValues.integrationAllTouch === true ? 'Enabled' : 'Disabled'}</td>
                </tr>
                {allTouchConfigVisible && (<><tr>
                  <th scope="row">Rauland AllTouch IP / Host</th>
                  <td className={submitValues?.allTouchIp !== initialValues.allTouchIp ? 'text-danger' : ''}>{submitValues?.allTouchIp}</td>
                  <td>{initialValues.allTouchIp}</td>
                </tr>
                <tr>
                  <th scope="row">Rauland AllTouch Port</th>
                  <td className={submitValues?.allTouchPort !== initialValues.allTouchPort ? 'text-danger' : ''}>{submitValues?.allTouchPort}</td>
                  <td>{initialValues.allTouchPort}</td>
                </tr></>)}
                <tr className="table-primary">
                  <th scope="row">Integration: Rauland Sip</th>
                  <td className={submitValues?.integrationSip !== initialValues.integrationSip ? 'text-danger' : ''}>{submitValues?.integrationSip === true ? 'Enabled' : 'Disabled'}</td>
                  <td>{initialValues.integrationSip === true ? 'Enabled' : 'Disabled'}</td>
                </tr>
                {sipConfigVisible && (<>
                <tr>
                  <th scope="row">Rauland SIP IP / Host</th>
                  <td className={submitValues?.sipIp !== initialValues.sipIp ? 'text-danger' : ''}>{submitValues?.sipIp}</td>
                  <td>{initialValues.sipIp}</td>
                </tr>
                <tr>
                  <th scope="row">Rauland SIP Port</th>
                  <td className={submitValues?.sipPort !== initialValues.sipPort ? 'text-danger' : ''}>{submitValues?.sipPort}</td>
                  <td>{initialValues.sipPort}</td>
                </tr>
                <tr className="table-primary">
                  <th scope="row">Integration: Rauland Sync</th>
                  <td className={submitValues?.integrationSync !== initialValues.integrationSync ? 'text-danger' : ''}>{submitValues?.integrationSync === true ? 'Enabled' : 'Disabled'}</td>
                  <td>{initialValues.integrationSync === true ? 'Enabled' : 'Disabled'}</td>
                </tr></>)}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={submitForm}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <>
            {submitError && (
                <div className="alert alert-danger" role="alert">{submitError}</div>
            )}
        </>
        <>
            {installationPackage && (
                <a href={installationPackage}>Download Installation Package</a>
            )}
        </>
        <br />
        <>
            {imagePackage && (
                <a href={imagePackage}>Download Docker Images</a>
            )}
        </>
    </>)
}
